
/* navebar */
.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.nav .active {
  background-color: blue;
  text-decoration: none;
  color: #fff;
  border-radius: 10px;
}
.nav-item-home a, button{
  width: auto;
  padding: 0px 10px;
  /* float:right; */
  padding: 8px 10px;
  float: right;
  margin-right: 15px;
  box-sizing: inherit;
  font-size: 18px;
  color: #fff;
}
.nav-item-home a:hover {
  background-color: #0069d9;
  text-decoration: none;
  color: #fff !important;
  border-radius: 10px;
}
.btn-login {
  border-radius: 10px;
  text-align: center;
  background-color: #4c38e9eb;
  border-color: #4c38e9eb;
  color: #fff;
  width: 100px;
}
.btn-login:hover {
  color: #fff;
}
.btn-signup-nav {
  border-radius: 10px;
  background-color: #fff;
  border-color: #3e2ebdeb;
  color:#3e2ebdeb;
  font-size: 16px;
  width: 100px;
}
.banner-one {
  margin: 5% 0px;
}
.banner1-para {
  font-size: 20px;
  font-weight: 600;
}
.btn-signup {
  background-color: #4c38e9eb;
  border-color: #4c38e9eb;
  color: #fff;
  float: left;
}
.img-right {
  width :550px;
  height: 400px;
}
.banner-head {
  font-weight: 700;
}
/*banner-two */
.banner-two {
  position: relative;
  top:20%;
  margin: 0 auto;
}
.banner2-background {
  text-align: center;
  padding: 2% 0% 3% 0px;
  background-color: #e1fdf6;
  width: 100%;
} 
.banner2-head {
  font-size: 35px;
  font-weight: bold;
  line-height: 60px;
  text-align: center;
}
.btn-search {
    float: revert;
    text-align: center;
    background-color: #e1fdf6;
    border-color: #3e2ebdeb;
    border-radius: 5px;
    color: #3e2ebdeb;
    font-weight: 600;
    font-size: 16px;
    margin: 20px 0px
}
.banner2-para {
    /* position: static; */
    text-align: center;
    font-size: 24px;
    padding: 0% 10%;
}
/* banner-box3 */
.banner3-background {
  padding: 3% 0px;
}
.banner-row {
  width: 100%;
  margin: 25px 40px;
}
.icon-angle {
  margin: 0px 20px;
}
.learn-more-icon1 {
  padding: 20px 0px 0px 0px;
}
.banner-box{
  margin: 10px 35px;
  padding: 1% 3%;
  box-shadow: 0 4px 16px rgb(0 0 0 / 10%);
}
.img-icon {
  width: 50px;
}
.banner3-para {
    color: gray;
    font-size: 14px;
   text-align: justify;
}
.learn-more {
  color: #7142ed;
}
.banner3-head {
    margin: 10px 0px;
    color: #4c4a4a;
    font-weight: 600;
}
.banner-row3 {
  width: 100%;
  margin: 0px 0px 0px 90px;
}
/* footer css */
.footer-padding {
  padding: 10% 0px 10px 0px
}
.list-text {
  color: #161616;
  padding: 0px 70px;
  font-size: 20px;
}
.footer-copy {
  padding: 10% 15px;
  color: #2a2929;
}
.footer-right {
  padding: 16% 0px 30px 0px;
}
.footer-right-side{
  padding: 0% 4%;
}
.footer-right p{
  font-size: 16px;
  color: #161616;
  line-height: 10px;
}
.footer-address {
  font-size: 25px;
  font-weight: bold;
  color: #2a2929;
  margin-bottom: 15px;
}
.icon-whatsapp {
  width: 40px;
  height: 35px;
  color: gray
}
.icon-whatsapp:hover{
  width: 40px;
  height: 35px;
  color: darkgreen;
}
.icon-text {
  display: inline-block;
  transform: translate(8px, -10px);
}
.facebook-icon {
  height: 30px;
  width: 40px;
  color: gray;
}
.facebook-icon:hover{
  height: 30px ;
  width: 40px;
  color: #3B5998;
}
.twitter-icon {
  height: 30px;
  width: 40px;
  color: gray;
}
.twitter-icon:hover{
  height: 30px ;
  width: 40px;
  color: #00ACEE;
}
.linkedin-icon {
  height: 30px;
  width: 40px;
  color: gray;
}
.linkedin-icon:hover{
  height: 30px ;
  width: 40px;
  color: #0E76A8;
}
/* .twitter-icon {
  height: 40px;
  padding: 0px 14px;
   transform: translate(0px, -7px);  
  color: grey;
} */

/* .indeed-icon {
  height: 45px;
  transform: translateY(-9px);
} */
/* banner-four */
.banner4-background {
  background-color: #faf3f0;
  padding: 5% 0px;
}
.company-head {
  /* padding: 40px 0px 10px 0px; */
  font-size: bold;
  text-align: center;
  font-weight: 700;
}
.about-us p{
  text-align: justify;
  padding-right: 16px ;
  padding-left: 4px;
}
.company-subhead {
  text-align: center;
  font-size: 25px;
  padding: 0px 0px 30px 0px;
}
.banner-about {
  max-width: 40%;
  background-color: #e9e6f6;
  border-radius: 20px;
  padding: 20px 40px;
  margin: 1.3%;

}
.banner-team {
  max-width: 44%;
  background-color: #e9e6f6;
  border-radius: 20px;
  padding: 20px 40px;
  margin: 12px 30px;

}
.learn-more2 {
  padding: 15px 0px;
}
#root {
  overflow: hidden;
}
/* banner five */
.banner5-background {
  padding: 5% 7%;
  background-color: #161515;
}
.banner5-head {
  color:#faf3f0;
}
.btn-create {
  width: 56%;
  float: revert;
  text-align: center;
  background-color: #161515;
  border-color: #fff;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  height: 75px;
}
.btn-create:hover {
  color: #fff;
}
.icons-angels {
  display: inline-block;
}
.icon-angle1 {
  padding: 5px 1px;
  width: 30px;
  height: 20px;
  color: aqua;
  transform: translateY(30px);
}
.icon-angle2 {
  padding: 3px 1px;
  width: 30px;
  height: 20px;
  color: #858282e8;
  transform: translateY(36px);
}
.icon-angle3 {
  width: 30px;
  height: 20px;
  color: aqua;
  transform: translateY(30px);
}
.icons-angels1 {
  margin: 0px 70px;
}
.icons-angles2{
  margin: 0px 5px;
}

@media (max-width: 767px) {
  .banner-team {
    min-width: 85% !important;
  }
  .banner-one {
    min-width: 100% !important;
    text-align: center;
  }
  .btn-login {
    margin-bottom: 10px;
  }
  .img-right {
    width: 385px;
    height: 300px;
  }
  .banner2-head {
    padding: 15px 0px 0px;
    font-size: 25px;
    line-height: normal;
  }
  .banner2-para {
    font-size: 20px;
    padding: 0px;
  }
  .banner-row {
   width: 85% !important;
   margin: 0px;
  }
  .banner2-box {
    margin: 0px 0px 25px 0px;
  }
  .banner-five {
    margin: 15px 0px;
    min-width: 475px;
  }
  .footer-right p {
    line-height: 20px;
  }
  .banner5-heading{
    min-width: 100% !important;
    text-align: center;
  }
} 